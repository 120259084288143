import React from "react";
import {Helmet} from "react-helmet";

import ResearchAndDevelopmentCombinationsDiagram
    from "../../components/research-and-development/ResearchAndDevelopmentCombinationsDiagram";

import * as classes from "./biochip-mam.module.scss";

export default function ResearchAndDevelopmentLiquidBiochipPage() {
    return (
        <article>
            <Helmet>
                <title>Биочип MAM</title>
            </Helmet>
            <h2>Биочип MAM</h2>
            <p>Стадия: Подготовка регистрационных документов</p>
            <p><strong>Панель антител — ER</strong> (рецепторы к эстрогену), <strong>Ki67, PR</strong> (рецепторы к прогестерону), <strong>HER-2-neu</strong></p>
            <h3>Назначение</h3>
            <p>Определение рецепторного статуса при раке молочной железы.</p>
            <h3>ER (рецепторы к эстрогену)</h3>
            <p>Выявление рецепторов к стеройдным гормонам широко применяется при лечении гормонально-зависимых опухолей. Наличие рецепторов может свидетельствовать о прогнозе заболевания и эффективности терапии.</p>
            <h3>PR (рецепторы к прогестерону)</h3>
            <p>Выявление рецепторов к стеройдным гормонам широко применяется при лечении гормонально-зависимых опухолей. Наличие рецепторов может свидетельствовать о прогнозе заболевания и эффективности терапии.</p>
            <h3>HER-2-neu</h3>
            <p>Рецептор эпидермального фактора роста раковой клетки. Это — ген, который воздействует на мембранные рецепторы клетки, и стимулирует её к усиленному делению. В некоторых опухолях (чаще всего рак молочной железы, рак пищевода, рак желудка) присутствует гиперэкспрессия (повышенная активность) Her2Neu, что вызывает быстрое деление опухолевой клетки и её повышенную активность. Также снижается эффективность химиотерапии, лучевой терапии, гормональной терапии. Из-за этого опухоли с Her2neu позитивным статусом отличаются агрессивным течением.</p>
            <h3>Ki67</h3>
            <p>Антиген Ki67 является ядерным негистоновым белком, который присутствует на всех стадиях клеточного цикла кроме G0. В целом, Ki67 — хороший маркер пролиферации клеточных популяций. Индекс окраски антителом к Ki67 — надежный прогностический маркер для многочисленных неоплазий, включая астроцитомы 2 степени, олигодендроглиомы, карциномы толстого кишечника и молочной железы.</p>
            <ResearchAndDevelopmentCombinationsDiagram className={classes.combinationsDiagram}>
                <ResearchAndDevelopmentCombinationsDiagram.Heading>
                    Варианты комбинаций — ER, Ki67, PR, HER-2-neu
                </ResearchAndDevelopmentCombinationsDiagram.Heading>
                <ResearchAndDevelopmentCombinationsDiagram.Definition>
                    <p>ER +<br />PR +</p>
                    <p>ER -<br />PR +</p>
                    <p>ER +<br />PR -</p>
                </ResearchAndDevelopmentCombinationsDiagram.Definition>
                <ResearchAndDevelopmentCombinationsDiagram.DescriptionContainer>
                    <ResearchAndDevelopmentCombinationsDiagram.Description className={classes.combination1And2Description}>
                        Гормонально-зависимая опухоль
                    </ResearchAndDevelopmentCombinationsDiagram.Description>
                </ResearchAndDevelopmentCombinationsDiagram.DescriptionContainer>
                <ResearchAndDevelopmentCombinationsDiagram.Definition>
                    <p>ER +<br />PR +<br />HER-2-neu +</p>
                    <p>ER -<br />PR -<br />HER-2-neu -</p>
                </ResearchAndDevelopmentCombinationsDiagram.Definition>
                <ResearchAndDevelopmentCombinationsDiagram.DescriptionContainer>
                    <ResearchAndDevelopmentCombinationsDiagram.Description className={classes.combination1And2Description}>
                        Гормонально-зависимая опухоль
                    </ResearchAndDevelopmentCombinationsDiagram.Description>
                    <div className={classes.combination2Additional}>
                        <hr className={classes.combination2Arrow} />
                        <ResearchAndDevelopmentCombinationsDiagram.Description
                            className={classes.combination2AdditionalDescription}>
                            Таргетная терапия
                        </ResearchAndDevelopmentCombinationsDiagram.Description>
                    </div>
                </ResearchAndDevelopmentCombinationsDiagram.DescriptionContainer>
                <ResearchAndDevelopmentCombinationsDiagram.Heading>
                    Количественный подсчет
                </ResearchAndDevelopmentCombinationsDiagram.Heading>
                <ResearchAndDevelopmentCombinationsDiagram.Definition>
                    Ki67<br />
                    HER-2-neu
                </ResearchAndDevelopmentCombinationsDiagram.Definition>
                <ResearchAndDevelopmentCombinationsDiagram.DescriptionContainer>
                    <ResearchAndDevelopmentCombinationsDiagram.Description className={classes.combination3Description}>
                        Определение степени злокачественности опухоли, прогноз течения заболевания и раннего метастазирования
                    </ResearchAndDevelopmentCombinationsDiagram.Description>
                </ResearchAndDevelopmentCombinationsDiagram.DescriptionContainer>
            </ResearchAndDevelopmentCombinationsDiagram>
        </article>
    );
}
